import Vue from "vue";

/*TITLE*/
document.title = "Vistasur nevada | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Vistasur nevada";
Vue.prototype.$subtitle = "";

/*INTRO*/
Vue.prototype.$promoter = "Vistasur nevada";
Vue.prototype.$introSubtitle = "";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-linkfloor-feudal-natural--20230421120418.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo-1-vela-natural-deco-bremen-roble--20230421120434.jpg";
Vue.prototype.$image_bath2 = "banyo-2-vela-smoked-deco-bremen-roble-copia--20230421120451.jpg";
Vue.prototype.$image_room = "dormitorio-ac4-residence-1l-utah--20230421120403.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-linkfloor-feudal-natural--20230421120418.jpg",
  },
  {
    src: "salon-ac4-residence-1l-utah--20230421120423.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo-1-vela-natural-deco-bremen-roble--20230421120434.jpg",
  },
  {
    src: "banyo-1-linkfloor-feudal-natural-vela-smoked-limit-vela-smoked--20230421120439.jpg",
  },
  {
    src: "banyo-1-linkfloor-feudal-natural-vela-natural-deco-bremen-roble--20230421120445.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2-vela-smoked-deco-bremen-roble-copia--20230421120451.jpg",
  },
  {
    src: "banyo-2-linkfloor-natural-feudal-vela-smoked-limit-vela-smoked-copia--20230421120455.jpg",
  },
  {
    src: "banyo-2-linkfloor-feudal-natural-vela-natural-deco-bremen-roble-copia--20230421120404.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
  {
    src: "dormitorio-ac4-residence-1l-utah--20230421120403.jpg",
  },
  {
    src: "dormitorio-linkflor-feudal-natural--20230421120408.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/vistasurnevada/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20de%20san%20bernardo%2018196%20granada%20",
    text: "Calle de San Bernardo, 18196 (Granada)",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:952000456/646606636",
    text: "952000456/646606636",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@primehouse.es",
    text: "info@primehouse.es",
  },
];
